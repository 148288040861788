/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import { useState, useRef, useEffect } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';

import YouTube from "./../../../../assets/icons/youtube.svg";

interface Props {
    video: string;
    url: string;
}

function Item({ ...props }: Props) {
    
    return (

        <a className="relative col-span-1 h-[28vh] group min-h-[15rem] bg-black rounded-lg flex items-center justify-center overflow-hidden cursor-pointer" href={props.url} target="_blank">
            <div className="absolute w-full h-full flex justify-center items-center z-10 group-hover:-z-10 delay-300">
                <div className="flex flex-col gap-2 items-center opacity-100 group-hover:opacity-0 duration-300 transition-opacity ease-in-out">
                    <img className="w-8 h-auto" src={YouTube} alt="" />
                    <p className="uppercase font-semibold text-white text-sm tracking-wider">Click to watch on YouTube</p>
                </div>
            </div>
            <HoverVideoPlayer
                videoSrc={props.video}
                className="w-full overflow-hidden opacity-40 group-hover:opacity-100 duration-300 ease-in-out transition-opacity"
            />
        </a>
    );
}

export default Item;