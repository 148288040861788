import { InlineWidget } from "react-calendly";

function Calender() {
    return ( 
        <section className="flex items-center">
            <div className="w-full flex flex-col">
                <div className="w-full h-auto">
                    <InlineWidget url="https://calendly.com/john-lindholm/consultation-call?hide_gdpr_banner=1" styles={{
                    height: '700px'
                    }} />
                </div>
            </div>
        </section>
     );
}

export default Calender;