import Video from "./../../../../assets/hero/website.mp4";
import { useRef, useState } from "react";
import { setFlagsFromString } from "v8";
function Hero() {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [muted, setMuted] = useState(false);
    const [flag, setFlag] = useState(true);
    const handleToggleMute = () => setMuted(current => !current);


    return (
        <div id="hero" className="relative flex !justify-center items-center bg-black">
            {flag === true && (
                <div className="absolute w-full h-full">
                    <div className="w-full h-full flex justify-center items-center">
                        <p className="text-white font-3rem z-20 uppercase tracking-wider font-semibold text-xl">Click to play the intro...</p>
                    </div>
                </div>
            )}
            <video ref={videoRef} className="h-full max-w-full" onClick={() => {videoRef.current?.play(); setFlag(false);}} muted={muted}>
                <source src={Video} type="video/mp4" />
            </video>

            {muted === true ? (
                <button className="absolute right-0 m-10" onClick={handleToggleMute}>Unmute</button>
            ) : (
                <button className="absolute right-0 m-10" onClick={handleToggleMute}>Mute</button>
            )}
        </div>
    );
}

export default Hero;