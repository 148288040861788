function Footer() {
    return ( 
        <footer className="w-full flex justify-between px-[5%] py-5 md:text-[10px]">
            <p className="text-xs md:text-[12px]">© BeVisionary AB. All rights reserved.</p>

            <p className="text-xs md:text-[12px]">Powered by: <a className="text-xs font-semibold" href="https://wargsolution.se">Warg Solution AB</a></p>
        </footer>
     );
}

export default Footer;