import Item from "./item";

import Alliance from "./../../../../assets/videos/alliance.mp4";
import Faze from "./../../../../assets/videos/faze.mp4";
import NHL from "./../../../../assets/videos/nhl.mp4";

import Softbank from "./../../../../assets/videos/softbank.mp4";
import SevenAm from "./../../../../assets/videos/7am.mp4";
import Tiptapp from "./../../../../assets/videos/tiptapp.mp4";

function Video() {
    return ( 
        <section className="flex flex-col gap-10">
            <div className="flex justify-start w-full">
                <h1>Videography work</h1>
            </div>

            <div className="flex w-full justify-center">
                <div className="w-full grid grid-cols-3 gap-4 md:grid-cols-1">
                    <Item url="https://youtu.be/jHEwNvcOhyI" video={Faze} />
                    <Item url="https://youtu.be/wna772YRDgw" video={Softbank} />
                    <Item url="https://youtu.be/KuDCZKFkvy0" video={SevenAm} />

                    <Item url="https://youtu.be/csK6Y9p6N50" video={Tiptapp} />
                    <Item url="https://youtu.be/k4iEb1L3IHM" video={NHL} />
                    <Item url="https://youtu.be/p7JOPe31QZg" video={Alliance} />
                </div>
            </div>
        </section>
     );
}

export default Video;