import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Comment from "./comment";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Testimonials() {
    return ( 
        <section>
            <div className="w-full h-full flex flex-col">
                <div className="w-full flex justify-center tracking-wide">
                    <h1 className="">Testimonials</h1>
                </div>
                <div className="">
                    <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <Comment company="Ninjas in Pyjamas" author="Casey Al-kaisy" text="John got to work on several different projects, all with a focus on producing videos surrounding NIP and our professional teams. He traveled to events outside of Sweden where he filmed the journey of our athletes, produced and edited interviews as well as other video material. He was easy to work with and had no issues working within the team and company." />
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <Comment company="Tiptapp" author="Julia Arhammar" text="Working with John has been great from beginning to end. He quickly understands the brief and what you are trying to achieve and runs with it, always outperforming expectations. John is also very flexible both during filming and in post production, which is something I appreciate a lot!" />
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <Comment company="Endpoint" author="Peter Thompson" text="John has completed a number of video projects for us, from concept, to filming, to a final product. Every piece of work he has completed has been to the utmost professional standard and promptly changed any small edits we have needed. Excellent communication with management and easy to work with." />
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <Comment company="Crealon Media" author="André Führ" text="John has a very good sense of how to evoke feelings in the consumer with his understanding of storytelling and modern day internet culture. The best thing about John is that he has the comprehension about what the audiences want to see and hear." />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Comment company="Faction Interactive" author="Anwar Andersson" text="I would say that John has a good sense of quality, and he is skilled within many creative areas. He is also very perceptive and is therefore a very easy individual to work with. I would definitely like to work with John again in the future, and I have the intention of doing so as soon as our paths cross again." />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Comment company="AFKT" author="Dragos Cruceat" text="John never backs down from a challenge and he has proven that multiple times at AFKT. From accepting a 24 hour video pitch challenge to evolve and become a promising social selling creator - he will never give up until he succeeds. His eye for content, social media, and esports are attributes that make him very valuable. I have learned a lot from John and it's always a pleasure working with him." />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Comment company="Alliance" author="Kelly Ong Xiao Wei" text="John worked with Alliance for 1,5 years and showed himself as an all-around great video producer. From shooting the content to editing it, we could easily rely on him and he would contribute to projects from highlight-reels scale to big advertisement productions. He is easy to work with and always delivered according to deadlines." />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
     );
}

export default Testimonials;