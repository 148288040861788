import Company from "./company";

import Alliance from "./../../../../assets/companies/alliance.png";
import Faze from "./../../../../assets/companies/faze.png";
import Nip from "./../../../../assets/companies/nip.png";
import OyDrinks from "./../../../../assets/companies/oydrinks.png";
import SportsGamer from "./../../../../assets/companies/sportsgamer.png";

import Fnatic from "./../../../../assets/companies/fnatic.png";
import OG from "./../../../../assets/companies/og.png";
import EA from "./../../../../assets/companies/ea.png";
import Endpoint from "./../../../../assets/companies/endpoint.png";
import Fragbite from "./../../../../assets/companies/fragbite.png";

import SoftBank from "./../../../../assets/companies/softbank.png";
import SevenAM from "./../../../../assets/companies/7am.png";
import GenG from "./../../../../assets/companies/geng.png";
import Tundra from "./../../../../assets/companies/tundra.png";
import Tiptapp from "./../../../../assets/companies/tiptapp.png";


function Companies() {
    return ( 
        <div id="companies" className="flex flex-col gap-10">
            <div className="flex w-full flex-col">
                <h2 className="text-white 2md:text-xl">Companies & organisations</h2>
                <p className="text-white opacity-60 2md:text-sm">I have had the pleasure of working with</p>
            </div>
            <div className="w-full flex flex-wrap gap-y-10 justify-between">
                <Company img={Alliance} alt="" url="https://thealliance.gg/" />
                <Company img={Nip} alt="" url="https://nip.gl/" />
                <Company img={Faze} alt="" url="https://fazeclan.com/" />
                <Company img={OyDrinks} alt="" url="https://oydrinks.com/" />
                <Company img={Fnatic} alt="" url="https://fnatic.com/" />
                <Company img={OG} alt="" url="https://ogs.gg/" />
                <Company img={EA} alt="" url="https://www.ea.com/" />
                <Company img={Endpoint} alt="" url="https://endpoint.gg/" />
                <Company img={Fragbite} alt="" url="https://fragbite.se/" />
                <Company img={SoftBank} alt="" url="https://www.softbank.jp/en/" />
                <Company img={GenG} alt="" url="https://geng.gg/" />
                <Company img={Tundra} alt="" url="https://geng.gg/" />
            </div>
        </div>
     );
}

export default Companies;