import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  open?: boolean;
  setOpen?: any;
  url: string;
}

function QuickView({ ...props }: Props) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-[0.25]"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-[0.25]"
              >
                <Dialog.Panel className="relative overflow-hidden bg-white text-left shadow-xl transition-all md:w-full sm:w-[85%] sm:my-8">
                <div className="flex sm:flex-col gap-4 md:gap-2">
                    <div className="relative">
                      <img
                        src={props.url}
                        alt=""
                        className="w-[50vw] h-[90vh] object-cover object-center"
                      />
                      </div>
                      </div>
                    </Dialog.Panel>
                    </Transition.Child>
            </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default QuickView;
