import { Hero, Calender, Companies, Testimonials, Video, Photo, About } from "./sections/index";

function Home() {
    return ( 
        <>
            <Hero />
            <Calender />
            <Companies />
            <Testimonials />
            <Video />
            <Photo />
            <About />
        </>
     );
}

export default Home;