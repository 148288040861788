import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Header, Footer } from "./components/index";
import { Home } from "./pages/index";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} /> {/* So you always go to the first page what ever the URL says. */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
