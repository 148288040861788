interface Props {
    company: string;
    author: string;
    text: string;
}

function Comment( {...props}: Props ) {
    return ( 
        <div className="w-full h-[20rem] relative flex flex-col gap-10 justify-center items-center 2md:h-[25rem] sm:h-[28rem]">
            <div className="">
                <p className="text-ms text-black  px-[20%] text-center 2md:px-[10%] sm:px-[8%]">{props.text}</p>
            </div>

            <div className="flex flex-col items-center gap-1">
                <h2 className="text-xl">{props.company}</h2>
                <p className="text-sm">{props.author}</p>
            </div>
        </div>
     );
}

export default Comment;