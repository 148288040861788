interface Props {
    img: string;
    alt: string;
    url: string;
}

function Company( {...props}: Props ) {
    return ( 
        <a className="w-1/6 flex justify-center items-center opacity-70 transition-opacity ease-in-out duration-300 hover:opacity-100 cursor-pointer 2md:w-1/4 md:w-1/3" href={props.url} target="_blank">
            <img className="w-2/5 min-w-[5rem] h-auto" src={props.img} alt={props.alt} />
        </a>
     );
}

export default Company;