import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Instagram from "./../../assets/icons/instagram.svg";
import LinkedIn from "./../../assets/icons/linkedin.svg";
import TikTok from "./../../assets/icons/tiktok.svg";
import Twitter from "./../../assets/icons/twitter.svg";
import YouTube from "./../../assets/icons/youtube.svg";

function Header() {
    useEffect(() => {
      let url = window.location.href.split("/");
      let target = url[url.length - 1].toLowerCase();
      let element = document.getElementById(target);
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);
    return ( 
        <header className="py-3 px-[5%] w-full flex items-center justify-between absolute z-30">
            <div className="">
                <Link to="/">
                    <h3 className="text-white uppercase tracking-wide no-underline hover:no-underline">John Lindholm</h3>
                </Link>
            </div>

            <div className="flex gap-16">
                <div className="hidden">
                    <ul className="flex gap-5">
                        <Link
                            to="/contact"
                            onClick={() => {
                            let contact = document.getElementById("hero");
                            contact &&
                                contact.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <span className="text-white text-ms transition-all duration-300 ease-in-out hover:underline">Home</span>
                        </Link>

                        <Link
                            to="/reference"
                            onClick={() => {
                            let contact = document.getElementById("work");
                            contact &&
                                contact.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <span className="text-white text-ms transition-all duration-300 ease-in-out hover:underline">Work</span>
                        </Link>

                        <Link
                            to="/reference"
                            onClick={() => {
                            let contact = document.getElementById("about");
                            contact &&
                                contact.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <span className="text-white text-ms transition-all duration-300 ease-in-out hover:underline">About</span>
                        </Link>

                        <Link
                            to="/contact"
                            onClick={() => {
                            let contact = document.getElementById("contact");
                            contact &&
                                contact.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <span className="text-white text-ms transition-all duration-300 ease-in-out hover:underline">Contact</span>
                        </Link>
                    </ul>
                </div>

                <div className="flex gap-3">
                    <a href="https://twitter.com/PresidentSlendy" target="_blank">
                        <img className="stroke-white fill-white w-6 h-auto opacity-50 duration-300 cursor-pointer transition-opacity hover:opacity-100" src={Twitter} alt="twitter logo" />
                    </a>

                    <a href="https://www.youtube.com/channel/UCPGXha-RBAaOT3sTDn8Cnsw" target="_blank">
                        <img className="stroke-white fill-white w-6 h-auto opacity-50 duration-300 cursor-pointer transition-opacity hover:opacity-100" src={YouTube} alt="youtube logo" />
                    </a>

                    <a href="https://www.instagram.com/fyiJohn/" target="_blank">
                        <img className="stroke-white fill-white w-6 h-auto opacity-50 duration-300 cursor-pointer transition-opacity hover:opacity-100" src={Instagram} alt="instagram logo" />
                    </a>

                    <a href="https://www.linkedin.com/in/john-lindholm-a65aa516b/" target="_blank">
                        <img className="stroke-white fill-white w-6 h-auto opacity-50 duration-300 cursor-pointer transition-opacity hover:opacity-100" src={LinkedIn} alt="linkedin logo" />
                    </a>
                </div>
            </div>
        </header>
     );
}

export default Header;