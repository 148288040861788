import { useState } from "react";
import QuickView from "./quickview";
interface Props {
  two?: boolean;
  four?: boolean;
  mobile?: boolean;
  img: string;
}

function Item({ ...props }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className={`col-span-1 cursor-pointer bg-dark rounded-lg ${
          props.two
            ? "row-span-2 lg:row-span-1 md:row-span-1"
            : props.four
            ? "row-span-4 lg:row-span-2 md:row-span-1"
            : ""
        } ${props.mobile ? "lg:row-span-1" : ""}`}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${props.img})`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
        onClick={() => setOpen(true)}
      ></div>
      <QuickView open={open} setOpen={setOpen} url={props.img} />
    </>
  );
}

export default Item;
