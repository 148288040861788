import Image from "./../../../../assets/about/about.jpg";

function About() {
    return ( 
        <section className="flex flex-col gap-10">
            <div className="flex w-full justify-center">
                <div className="w-2/5 md:hidden">
                    <div className="rounded-lg w-full h-[85vh] lg:h-full" style={{
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image})`,
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,
                        backgroundPosition: `top`,
                    }}></div>
                </div>

                <div className="w-3/5 flex flex-col items-start justify-center md:w-full md:px-[10%]">
                    <div className="pl-[15%] pb-3 md:pl-0">
                        <h1>Hello,</h1>
                    </div>

                    <div className="w-full pl-[15%] md:pl-0">
                        <p>I'm a Sweden-based media producer examined in online communication.
                        <br></br><br></br>
                        For ⅔ of my life I've created content and worked as a one-man-team. I’ve learned everything from how to plan and execute a production, to distributing content that yields results on social media. 
                        <br></br><br></br>
                        As a social media content creator, I’m part of the future for online communication and marketing. Operating faster than bigger production companies and working closer to you, the client. 
                        <br></br><br></br>
                        Schedule a meeting and I'll tell you more!
                        </p>
                    </div>

                    <div className="pl-[15%] pt-10 md:pl-0">
                        <a id="button" href="https://calendly.com/john-lindholm/consultation-call" target="_blank">Schedule a meeting</a>
                    </div>
                </div>
            </div>
        </section>
     );
}

export default About;