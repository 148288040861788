import Item from "./item";

import Vone from "./../../../../assets/photos/vertical/p1.jpg";
import Vtwo from "./../../../../assets/photos/vertical/p2.jpg";
import Vthree from "./../../../../assets/photos/vertical/p3.jpg";
import Vfour from "./../../../../assets/photos/vertical/p4.jpg";

import Hone from "./../../../../assets/photos/horisontal/p1.jpg";
import Htwo from "./../../../../assets/photos/horisontal/p2.jpg";
import Hthree from "./../../../../assets/photos/horisontal/p3.jpg";
import Hfour from "./../../../../assets/photos/horisontal/p4.jpg";

function Photo() {
    return ( 
        <section className="flex flex-col gap-10">
            <div className="flex justify-start w-full">
                <h1>Photography work</h1>
            </div>

            <div className="flex w-full justify-center">
                <div className="w-full grid grid-cols-4 grid-rows-6 gap-4 h-[80vh] lg:grid-cols-2 lg:h-[140vh] md:grid-cols-2 md:grid-rows-4 md:h-[100vh] sm:grid-cols-1">
                    <Item img={Hone} two />
                    <Item img={Vone} four />
                    <Item img={Htwo} two />
                    <Item img={Vtwo} four />

                    <Item img={Hthree} four />
                    <Item img={Vthree} four />
                    <Item img={Hfour} two />
                    <Item img={Vfour} two />
                </div>
            </div>
        </section>
     );
}

export default Photo;